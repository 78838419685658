import { useEffect, useRef, React } from "react";
import styled from "styled-components";
import honklerLogo from "./logo_new.png";
import telegramLogo from "./telegram.jpg";
import twitterLogo from "./twitter.jpg";
import etherscanLogo from "./etherscan.png";
import dexscreenerLogo from "./dexscreener.png";
import honklerImage from "./hmm_eth.png";
import bgImage from "./bg.png";
import photoCollage1 from "./photo-collage1.png";
import photoCollage2 from "./photo-collage2.png";
import theplan from "./theplan.jpg";
import mexcLogo from "./mexc.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${bgImage}) no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  padding: 20px;
  color: white;
  font-family: "Comic Neue", cursive;
`;

const HeaderText = styled.div`
  font-family: "DynaPuff", cursive;
  font-size: 24px;
  color: white;
  margin-left: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  z-index: 10;
  box-sizing: border-box;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 100px;
  height: 100px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px; /* Ensure there's space for the fixed header */
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: calc(50px + 2vw); /* Responsive margin */
`;

const SocialButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const Tooltip = styled.div`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the button */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: "";
    position: absolute;
    top: 100%; /* Arrow on the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

const MainButtonStyled = styled.button`
  position: relative;
  font-family: "Comic Neue", cursive;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background: linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet, red, orange, yellow, green, blue, indigo, violet);
  background-size: 800% 800%;
  border: 4px solid black;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  transform: rotate(5deg);
  box-shadow: 3px 3px 0px #000000;
  transition: transform 0.2s;
  animation: rainbow 10s linear infinite;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

  &:hover {
    transform: scale(1.05);
  }

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  @keyframes rainbow {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 100%;
    }
  }

  @media (min-width: 768px) {
    font-size: 28px;
    padding: 15px 30px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
    padding: 20px 40px;
  }
`;

const SocialButton = styled.a`
  img {
    width: 40px;
    height: 40px;
    border: 2px solid black;
    border-radius: 50%;

    @media (min-width: 768px) {
      width: 50px;
      height: 50px;
    }

    @media (min-width: 1024px) {
      width: 60px;
      height: 60px;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 60px;
  position: relative;
`;

const TextField = styled.div`
  padding: 10px;
  color: white;
  font-family: "Comic Neue", cursive;
  font-size: 22px;
  text-align: center;
  max-width: 45%;
  z-index: 1;
  margin-left: 10px;
  font-weight: 700;
  text-shadow: 2px 2px 4px black;

  @media (min-width: 768px) {
    font-size: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 44px;
  }

  @media (min-width: 1200px) {
    font-size: 50px;
  }
`;

const Strikethrough = styled.span`
  text-decoration: line-through;
`;

const HighlightRed = styled.span`
  color: red;
`;

const Image = styled.img`
  width: 60%;
  z-index: 0;
  
  @media (min-width: 768px) {
    width: 40%;
  }

  @media (min-width: 1024px) {
    width: 30%;
  }
`;

const CollageWrapper = styled.div`
  width: 100%;
  background-color: black;
  padding: 20px 0;
`;

const PhotoCollage = styled.img`
  width: 100%;
`;

const ThePlanImage = styled.img`
  width: 100%;
  margin-top: 20px;
`;

const OutlinedText = styled.span`
  font-weight: 700;
  -webkit-text-stroke: 1px black;
`;

const Banner = styled.a`
  width: 100%;
  background: rgba(0, 175, 0, 1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  position: fixed;
  top: 120px;
  z-index: 10;
  font-family: "DynaPuff", cursive;
  text-decoration: none;
`;

const GlowingText = styled.div`
  text-align: center;
  font-family: "DynaPuff", cursive;
  color: white;
  animation: pulse 2s infinite;
  font-size: calc(8px + 2vw);

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @media (min-width: 768px) {
    font-size: calc(8px + 1.5vw); /* Adjust font size for medium screens */
  }

  @media (min-width: 1024px) {
    font-size: calc(10px + 1vw); /* Adjust font size for large screens */
  }

  @media (min-width: 1200px) {
    font-size: 32px; /* Maximum font size */
  }
`;

const MexcLogo = styled.img`
  height: calc(10px + 1.5vw); /* Responsive height */
  max-height: 32px;
  vertical-align: -2px;
  `;


const HonkHonkVideo = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Ensure the video is muted and attempt to play
      video.muted = true;
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Autoplay worked
          })
          .catch((error) => {
            // Autoplay was prevented, show play button or handle accordingly
            console.error("Autoplay prevented:", error);
          });
      }
    }
  }, []);

  return (
    <div
      style={{ width: "100%", position: "relative", paddingBottom: "56.25%" }}
    >
      <video
        ref={videoRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover", // Ensure the video covers the area
        }}
        src="/honkhonk.webm"
        type="video/webm"
        autoPlay
        loop
        muted
        playsInline
        webkit-playsinline="true" // Corrected here
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const App = () => {
  return (
    <div>
      <Header>
        <HeaderContent>
          <Logo src={honklerLogo} alt="Honkler Logo" />
          <HeaderText>
            The honking shall continue until morale improves.
          </HeaderText>
        </HeaderContent>
      </Header>
      <Banner href="https://www.mexc.com/exchange/HONKLER_USDT?_from=search_spot_trade" target="_blank">
        <GlowingText>
          Yo @DudeLampoon you gonna buy this domain or what
        </GlowingText>
      </Banner>
      <Container>
        <ContentWrapper>
          <ButtonWrapper>
            <HonkButton />
            <BuyButton />
            <SocialButtons>
              <SocialButton href="https://t.me/honklereth" target="_blank">
                <img src={telegramLogo} alt="Telegram" />
              </SocialButton>
              <SocialButton href="https://x.com/honklereth" target="_blank">
                <img src={twitterLogo} alt="Twitter" />
              </SocialButton>
              <SocialButton
                href="https://etherscan.io/token/0x69d26c4901765ffa6d7716045b680c9574cb00b5"
                target="_blank"
              >
                <img src={etherscanLogo} alt="Etherscan" />
              </SocialButton>
              <SocialButton
                href="https://dexscreener.com/ethereum/0x22c8509a41ebb592312e76d761de3996da5cd732"
                target="_blank"
              >
                <img src={dexscreenerLogo} alt="DexScreener" />
              </SocialButton>
            </SocialButtons>
          </ButtonWrapper>
          <ContentContainer>
            <Image src={honklerImage} alt="Honkler"/>
            <TextField>
              <OutlinedText>
                &gt;he got <HighlightRed>rugged on</HighlightRed>{" "}
                <Strikethrough>$NORMIE</Strikethrough>
                <br />
                &gt;he got <HighlightRed>priced out</HighlightRed> of{" "}
                <Strikethrough>$PEPE</Strikethrough>
                <br />
                &gt;he <HighlightRed>panic sold</HighlightRed>{" "}
                <Strikethrough>$NPC</Strikethrough>
              </OutlinedText>
            </TextField>
          </ContentContainer>
          <CollageWrapper>
            <PhotoCollage src={photoCollage1} alt="Photo Collage 1" />
            <PhotoCollage src={photoCollage2} alt="Photo Collage 2" />
          </CollageWrapper>
          <HonkHonkVideo />
          <ThePlanImage src={theplan} alt="The Plan" />
        </ContentWrapper>
      </Container>
    </div>
  );
};

const BuyButton = () => {
  return (
    <MainButtonStyled
      onClick={() =>
        window.open(
          "https://app.uniswap.org/swap?theme=dark&chain=mainnet&use=v2&outputCurrency=0x69d26c4901765ffa6d7716045b680c9574cb00b5",
          "_blank"
        )
      }
    >
      {" "}
      BUY $HONKLER{" "}
      <Tooltip className="tooltip">Buy on Uniswap</Tooltip>
    </MainButtonStyled>
  );
};

const HonkButton = () => {
  return (
    <MainButtonStyled
      onClick={() =>
        window.open(
          "https://honkme.honkler.com",
          "_blank"
        )
      }
    >
      {" "}
      GO HONK!{" "}
    <Tooltip className="tooltip">Go to editor</Tooltip>
    </MainButtonStyled>
  );
};

export default App;
